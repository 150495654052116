<template>
  <div class="agentInfo">
    <div class="facilityBox">
      <el-breadcrumb separator="/" style="margin:0 0 10px 0;">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>客户管理</el-breadcrumb-item>
        <el-breadcrumb-item>查看代理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-row class="settleBox">
        <el-col :span="4">代理商名称：<strong>{{ queryInfo.AgentName }}</strong></el-col>
        <el-col :span="4">手机号：<strong>{{ queryInfo.AgentPhone }}</strong></el-col>
        <el-col :span="8">公司地址：<strong>{{
          queryInfo.CompanyAddress
            ? queryInfo.CompanyAddress
            : queryInfo.AgentAdress
        }}</strong></el-col>
      </el-row>
      <!-- 搜索表单 -->
      <el-form ref="searchForm" inline :model="searchForm">
        <el-form-item label="数据筛选：" prop="status" label-width="100px">
          <el-select @change="search" v-model="searchForm.status" placeholder="请选择" style="width: 180px">
            <el-option v-for="item in typeList" :label="item.Label" :value="item.Text" :key="item.Value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提交日期：" prop="time" label-width="100px">
          <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="至" start-placeholder="开始时间"
            value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" icon="el-icon-zoom-in" @click="search">搜索</el-button>
        <el-button icon="el-icon-delete" @click="$refs['searchForm'].resetFields(), getTableList()">清空</el-button>
        <el-button size="small" @click="routerBack()" icon="el-icon-arrow-left">返回</el-button>
      </el-form>
      <!-- 表格 -->
      <el-table :data="pagination.list" v-loading="pagination.loading"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }">
        <el-table-column align="center" type="index" prop="index" label="序号" width="50" fixed="left"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Name" label="公司名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ContactsPhone" label="手机号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="StatusName" label="客户状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- 0待跟进；1更进中；2流失；3成交 -->
            <el-tag :type="
              scope.row.Status == 0 || scope.row.Status == 1
                ? 'warning'
                : scope.row.Status == 2
                  ? 'info'
                  : 'success'
            ">{{
  scope.row.Status == 0
    ? "待跟进"
    : scope.row.Status == 1
      ? "跟进中"
      : scope.row.Status == 2
        ? "已流失"
        : "已成交"
}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="AgentName" label="所属代理" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Province,City,County,Address" label="公司地址" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.Province }}{{ scope.row.City }}{{
              scope.row.County
            }}{{ scope.row.Address }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="AddTime" label="添加时间" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="$refs.ctrDetail.customerDetail(scope.row)">查看客户</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="display:flex; align-items:center;margin-top: 10px">
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>

      <customer-detail ref="ctrDetail"></customer-detail>
    </div>
  </div>
</template>

<script>
import {
  GetAgentList,
  GetCustomerList,
  GetLostRecord,
  GetAgentToCustomerList,
} from "@/api/agentManage/customerManage";
import customerDetail from "./customerDetail";
export default {
  data() {
    return {
      typeList: [
        {
          Label: "全部",
          Text: "",
          Value: 99,
        },
        {
          Label: "待跟进",
          Text: "待跟进",
          Value: 0,
        },
        {
          Label: "跟进中",
          Text: "跟进中",
          Value: 1,
        },
        {
          Label: "已流失",
          Text: "流失",
          Value: 2,
        },
        {
          Label: "已成交",
          Text: "成交",
          Value: 3,
        },
      ],
      searchForm: {
        searchContent: "", //名称
        status: "", //数据筛选
        time: ["", ""],
      },
      pagination: {
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
        list: [],
      },
      queryInfo: {},
      customerDrawer: false,
    };
  },
  components: {
    "customer-detail": customerDetail,
  },
  methods: {
    //获取代理信息
    getTableList() {
      this.pagination.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        stasus: this.searchForm.status,
        ascriptionUserId:
          this.queryInfo.AgentId || this.queryInfo.AscriptionUserID,
        startDateTime: this.searchForm.time ? this.searchForm.time[0] : "",
        endDateTime: this.searchForm.time ? this.searchForm.time[1] : "",
      };
      GetAgentToCustomerList(params)
        .then((res) => {
          this.pagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.pagination.page - 1) * this.pagination.pagesize + index + 1;
            item.AgentName = this.queryInfo.AgentName;
            return item;
          });
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },
    //搜索
    search() {
      this.pagination.loading = true;
      this.pagination.page = 1;
      this.pagination.list = [];
      this.getTableList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.loading = true;
      this.pagination.page = e;
      this.getTableList();
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/agentManage/customerManage/index",
        query: {
          pageIndex: this.$route.query.pageIndex,
          startTime: this.$route.query.startTime,
          endTime: this.$route.query.endTime,
          searchContent: this.$route.query.searchContent,
          status: this.$route.query.status,
          activePage: this.$route.query.activePage,
        },
      });
    },
  },
  created() {
    this.queryInfo = JSON.parse(this.$route.query.itemInfo);
    this.getTableList();
  },
};
</script>

<style lang="scss" scoped>
.agentInfo {
  .settleBox {
    margin: 15px 0px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }
}
</style>
